






















import { Component, Vue } from 'vue-property-decorator'

import AuthModule from '@/store/modules/auth'
// import SystemModule from '@/store/modules/system'
import Logo from '@/components/_uikit/Logo.vue'

@Component({
  components: {
    Logo,
  },
})
export default class Docs extends Vue {
  private isLoaded = false

  private created () {
    AuthModule.fetchUser()
      .finally(() => {
        this.isLoaded = true
      })
  }

  private get title() {
    if (this.$route.name === 'docs.policy') {
      return 'Политика конфиденциальности персональных данных'
    } else if (this.$route.name === 'docs.offer') {
      return 'Договор оферты'
    } else if (this.$route.name === 'docs.personal') {
      return 'Согласие на обработку персональных данных'
    }
    return 'Правовая информация'
  }

  private get breadcrumbs () {
    if (this.$route.name === 'docs') {
      return []
    }
    return [
      { name: 'Правовая информация', path: '/docs' },
    ]
  }

  private get isAuth() {
    return AuthModule.self
  }
}
